import React from 'react';
import { Link } from 'gatsby';

const MoreStories = ({ data }) => {
  let blogUrl = '/blog';

  let topStories = Object.entries(data).filter((x) => !x[0]?.includes('allStrapi') && !x[0]?.includes('latest'));

  return (
    <div className="w-full my-16">
      <div className="max-w-6xl mx-auto">
        <h1 className="my-16 text-5xl text-center uppercase md:text-5xl text-blue">Top Stories</h1>
        <div className="grid max-w-4xl grid-cols-1 gap-4 mx-auto md:grid-cols-2">
          {topStories?.map((_item) => {
            let item = _item[1];
            return (
              <div
                key={`post-${item?.edges[0]?.node?.slug}`}
                className="relative w-full bg-center bg-cover cursor-pointer h-96 lg:h-72 md:h-56 story-box"
                style={{ backgroundImage: `url(${item?.edges[0]?.node?.image[0]?.url})` }}
              >
                <div className="story-overlay">
                  <p className="mb-3 text-lg text-white">
                    {item?.edges[0]?.node?.categories[0]?.name === undefined
                      ? ''
                      : item?.edges[0]?.node?.categories[0]?.name}
                  </p>
                  <Link to={`/blog/${item?.edges[0]?.node?.slug}`}>
                    <h2
                      className="text-5xl text-white uppercase transition-all md:text-3xl lg:text-4xl "
                      style={{
                        textShadow: '#000 1px 0 10px',
                      }}
                    >
                      {item?.edges[0]?.node?.title}
                    </h2>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center mt-20">
          <Link
            to={blogUrl}
            className="py-2 transition-all border cursor-pointer text-blue border-blue px-14 hover:bg-blue hover:text-white"
          >
            View All Articles
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MoreStories;
