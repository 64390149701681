import React, { useState } from 'react';
import { AiOutlineArrowRight } from '@react-icons/all-files/ai/AiOutlineArrowRight';
import EventPagination from '../Pagination/EventPagination';

const Event = ({ data }) => {
  const [events] = useState(data.allStrapiEvents.edges);
  const [currentPage, setCurrentPage] = useState(1);
  const dataLimit = 2;
  const numPages = Math.ceil(events.length / dataLimit);
  const pageLimit = numPages;

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return events.slice(startIndex, endIndex);
  };

  const convertDate = (date) => {
    let newDate = new Date(date);
    const options = { month: 'short', day: 'numeric' };
    return newDate.toLocaleDateString('en-US', options);
  };

  const convertDateToTime = (date) => {
    let newDate = new Date(date);
    const options = { hour: 'numeric', minute: 'numeric' };
    return newDate.toLocaleTimeString('en-US', options);
  };

  return events.length === 1 && events[0].node.title === 'No events at this time.' ? (
    <></>
  ) : (
    <div className="w-full mx-auto bg-white shadow-xl max-w-7xl" id="events">
      <div className="w-full py-20 mx-auto ">
        <h2 className="pb-16 text-5xl text-center uppercase text-blue">Upcoming Events</h2>
        <div className="flex flex-col w-full mb-8 justify-evenly md:items-center lg:flex-row">
          {getPaginatedData().map((item, idx) => (
            <div
              className="flex flex-col items-center w-full max-w-lg mb-6 md:px-24 lg:px-0 lg:flex-row lg:mb-0"
              key={idx}
            >
              <img
                // This is a temporary fix to display the correct image while allowing us to remove the innovation CloudFront alias, future fix will be to update the image URL in Strapi
                src={
                  item.node.image[0].url.includes('innovation')
                    ? item.node.image[0].url.replace('innovation', 'nmdsi')
                    : item.node.image[0].url
                }
                className="object-cover w-56 h-56 mb-3 sm:object-contain sm:w-full -full lg:mb-0"
              />
              <div className="flex flex-col items-center w-full sm:items-baseline lg:ml-4">
                <h2 className="text-4xl text-blue">
                  {convertDate(item.node.start) === convertDate(item.node.end)
                    ? convertDate(item.node.start)
                    : `${convertDate(item.node.start)} - ${convertDate(item.node.end)}`}
                </h2>
                <span className="text-xl text-blue">{item.node.title}</span>

                <span className="mb-1 text-sm text-brown">
                  {convertDateToTime(item.node.start)}-{convertDateToTime(item.node.end)} CST
                </span>

                <a
                  target="__blank"
                  href={item.node.registrationUrl}
                  className="flex items-center transition-all cursor-pointer text-blue"
                >
                  Register Here
                  <AiOutlineArrowRight className="ml-2 text-blue hover:text-yellow" />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center w-full ml-5">
          <EventPagination
            currentPage={currentPage}
            pageLimit={pageLimit}
            setCurrentPage={setCurrentPage}
            numPages={numPages}
          />
        </div>
      </div>
    </div>
  );
};

export default Event;
