import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SpacePic from '../../assets/images/DSI_about_partner.png';

const Space = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col justify-end w-full md:flex-row bg-blueOverlay">
        <div className="bg-top bg-cover md:w-1/2 h-120" style={{ backgroundImage: `url(${SpacePic})` }}>
          <div className="flex flex-col w-full h-120 bg-blueOverlay">
            <div className="max-w-2xl px-10 mx-auto my-auto">
              <h2 className="text-4xl text-white uppercase md:text-5xl ">about our partners</h2>
              <br />
              <p className="text-white ">
                The unique partnership between industry and academia sets the Northwestern Mutual Data Science Institute
                apart from other data science institutions and programs. By combining the forces of two nationally
                recognized universities and a recognized leader in the financial services industry, we understand the
                need for data science education and development and are taking concrete steps to build and retain
                technology talent, ensuring the Milwaukee region remains economically competitive.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-around w-full py-5 bg-gray-100 md:w-1/2 h-120">
          <h2 className="text-5xl text-center uppercase text-blue">Learn More </h2>
          <div className="flex flex-col items-center space-y-4 sm:space-x-8 sm:flex-row ">
            <a
              href="https://www.northwesternmutual.com/"
              target="_blank"
              rel="noreferrer"
              className="transition duration-500 ease-in-out transform cursor-pointer hover:-translate-y-1 hover:scale-110"
            >
              <StaticImage
                src="../../assets/images/DSI_nm_logo.png"
                alt="Northwestern Mutual Data Science Institute Logo"
                className="w-52"
                id="staticImg"
              />
            </a>
          </div>
          <div className="flex flex-col items-center space-y-4 sm:space-x-8 sm:flex-row ">
            <a
              href="https://www.marquette.edu/"
              target="_blank"
              rel="noreferrer"
              className="transition duration-500 ease-in-out transform cursor-pointer hover:-translate-y-1 hover:scale-110"
            >
              <StaticImage
                src="../../assets/images/DSI_mu_logo.png"
                alt="Marquette University Logo"
                className="w-52"
                id="staticImg"
              />
            </a>
          </div>
          <div>
            <div className="flex flex-col items-center space-y-4 sm:space-x-8 sm:flex-row ">
              <a
                href="https://uwm.edu/"
                target="_blank"
                rel="noreferrer"
                className="transition duration-500 ease-in-out transform cursor-pointer hover:-translate-y-1 hover:scale-110"
              >
                <StaticImage
                  src="../../assets/images/DSI_uwm_logo.png"
                  alt="UW Milwaukee Logo"
                  className="w-52"
                  id="staticImg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Space;
