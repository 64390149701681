import React, { useState, useRef } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import * as yup from 'yup';

import BackToTop from './BackToTop';

const Contact = ({ isNMDSI }) => {
  const diTopic = 3400;
  const nmdsiTopic = 3409;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const recaptchaRef = useRef({});

  const schema = yup.object().shape({
    name: yup.string().required().max(80),
    email: yup.string().required().email(),
    message: yup.string().required().min(10).max(2000),
    agree: yup.bool().oneOf([true], 'Online Privacy Statement is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const onSubmit = async (data, e) => {
    setIsSubmitting(true);
    try {
      const token = await recaptchaRef.current.executeAsync();
      if (token) {
        const form = e.target;
        fetch('/api/contact', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: encode({
            'form-name': form.getAttribute('name'),
            recaptchaToken: token,
            topic: isNMDSI ? nmdsiTopic : diTopic,
            ...data,
          }),
        });

        setIsSubmitted(true);
      }
    } catch (e) {
      setIsSubmitted(false);
      setIsSubmitError(true);
      console.error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full py-32 bg-blue" id="contact">
      <div className="max-w-5xl px-5 mx-auto md:px-2">
        <h1 className="mb-6 text-5xl text-center uppercase text-yellow">Let&apos;s connect</h1>
        <div className="mx-auto mb-10 text-white md:max-w-lg">
          <p className="mb-4 text-center">
            If you have questions or would like to get in touch regarding Innovation and Technology at Northwestern
            Mutual, please contact us using the form below.
          </p>
          <p className="text-center">
            For media inquiries, please contact (800) 323-7033
            <br />
            <a href="https://news.northwesternmutual.com/news-releases">
              <span className="text-teal hover:text-yellow"> Visit our corporate newsroom. </span>
            </a>
          </p>
        </div>
        {isSubmitted ? (
          <div className="flex justify-center mx-auto bg-gray-100 ">
            <div className="flex-col px-5 py-10 space-y-12 text-center">
              <div>
                <h3 className="text-4xl font-bold text-blue"> Thank you</h3>
              </div>
              <div>
                <span className="text-xl font-bold text-blue">Your message has been sent</span>
              </div>
            </div>
          </div>
        ) : (
          <form name="contact" id="contactForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center mb-3 contact">
              <div className="flex flex-col">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  className={classNames('px-4 py-2.5 mb-4', { 'border-2': errors.name, 'border-red-300': errors.name })}
                  {...register('name')}
                />
                {errors?.name && <span className="mb-2 text-red-300 capitalize"> {errors.name?.message}</span>}
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className={classNames('px-4 py-2.5 mb-4', {
                    'border-2': errors.email,
                    'border-red-300': errors.email,
                  })}
                  {...register('email')}
                />
                {errors?.email && <span className="mb-2 text-red-300 capitalize"> {errors.email?.message}</span>}
                <textarea
                  id="message"
                  name="message"
                  rows={5}
                  maxLength={2000}
                  placeholder="Message"
                  className={classNames('px-4 py-2.5 mb-4', {
                    'border-2': errors.message,
                    'border-red-300': errors.message,
                  })}
                  {...register('message')}
                />
                {errors?.message && <span className="mb-2 text-red-300 capitalize"> {errors.message?.message}</span>}
              </div>
            </div>
            <div className="max-w-lg pl-2 mx-auto mb-12">
              <div className="flex justify-center w-full">
                <input
                  id="agree"
                  name="agree"
                  type="checkbox"
                  className={classNames('mr-3', { border: errors.agree, 'border-red-300': errors.agree })}
                  {...register('agree')}
                />
                <p className="text-xs text-left text-white sm:text-center">
                  By submitting this form, you agree to the site&apos;s{' '}
                  <a
                    className="text-xs text-left text-teal hover:text-yellow sm:text-center"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.northwesternmutual.com/online-privacy-statement/"
                  >
                    online privacy statement
                  </a>
                  .
                </p>
              </div>
              {errors?.agree && (
                <div className="flex justify-center w-full">
                  <span className="mb-2 text-red-300 capitalize">{errors.agree?.message}</span>
                </div>
              )}
            </div>
            {isSubmitError && (
              <div className="max-w-lg p-2 pl-2 mx-auto mb-12 text-center text-white bg-red-400 rounded">
                <p>We were unable to send the form at this time. Please try again later.</p>
              </div>
            )}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isSubmitting}
                className="text-white border border-solid cursor-pointer border-yellow px-14 hover:bg-yellow hover:text-blue"
              >
                {isSubmitting ? 'Submitting...' : 'Send Message'}
              </button>
            </div>
          </form>
        )}
      </div>
      <Recaptcha sitekey="6LdxamEUAAAAAOKDW603fb3tE3DqVHqzUQzGAnoZ" size="invisible" ref={recaptchaRef} />
      <div className="flex justify-center w-full mx-auto lg:justify-end max-w-7xl">
        <BackToTop />
      </div>
    </div>
  );
};

export default Contact;
