import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => {
  return (
    <div className="w-full nmdsi-header">
      <div className="flex flex-col justify-center w-full p-10 mx-auto">
        <StaticImage src="../../assets/images/NMDSI_Header_Logos.png" className="w-full mx-auto my-6 md:w-5/12" />
        <div className="flex flex-col items-center">
          <a
            href="https://www.surveymonkey.com/r/3JRY6Q7"
            className="bg-blue text-white px-12 py-2.5 cursor-pointer mx-auto hover:bg-yellow hover:text-blue my-2"
          >
            Subscribe to our newsletter
          </a>
          <a
            href="https://www.linkedin.com/showcase/nmdsi/"
            className="bg-blue text-white px-12 py-2.5 cursor-pointer mx-auto hover:bg-yellow hover:text-blue"
          >
            Follow us on LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
